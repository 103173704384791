import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function UXDesignLead () {
  return (
    <CareerLayout title='UX design lead' location='Remote'>
      <h2>Overview</h2>
      <p className='mb'>As a UX Design Lead at Trexity, you’ll be part of a growing team and will be working on all user facing areas of our platform. You will work on areas such as our mobile app for drivers,  our merchant-facing portal, and our internal-facing dashboards and tooling. You will collaborate closely with the product and engineering teams, taking part in scoping and design discussions, customer conversations, and internal and external feedback reviews.</p>

      <h2>What you might do</h2>
      <ul className='trx-bullet mb'>
        <li>Unify user experience across the platform to mitigate learning curve no matter who is using the platform</li>
        <li>Improve the driver experience of our mobile app for drivers to ensure drivers stay happy and efficient while reducing errors</li>
        <li>Improve the experience of our merchant-facing portal to ensure the merchant delivery experience is streamlined</li>
        <li>Work on the experience of the addressee to ensure customers receiving packages are delighted every time they get a delivery</li>
        <li>Help ensure user experience remains consistent across website and marketing tools</li>
        <li>Partake in scoping and technical discussions to ensure UX is coordinated with the underlying technology in use</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>At least 5 years experience with UX, design, and wire-framing user experiences professionally</li>
        <li>Experience with tools such as Adobe XD, Sketch, Figma, Balsamiq, Photoshop</li>
        <li>Experience collaborating with multifaceted teams</li>
        <li>Experience working in a fast growth environment</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
